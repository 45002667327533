export const eyebrowsTattooPreCare = [
  "Ensure your skin is free from any irritations, including blemishes, eczema, and psoriasis, before your appointment.",
  "If you have been taking Accutane medication, it is crucial to have stopped it for at least three months before getting a cosmetic tattoo.",
  "Avoid using Fish Oil, Vitamin C, Glucosamine, Evening Primrose Oil, Ginger, and similar supplements one week before your appointment, as they can thin the blood and affect pigment implantation, potentially leading to patchy healing.",
  "Discontinue the use of anti-aging, skin brightening, or anti-acne skincare products containing Alpha Hydroxy (AHA), Vitamin A, Retinol, or similar ingredients for at least 30 days before your appointment. These products can interfere with the tattoo healing process, even if not applied directly to the treatment area.",
  "Refrain from scheduling facials, microdermabrasion, microneedling, peels, or laser treatments for at least 4 weeks before your appointment.",
  "Avoid cosmetic injections on the forehead for at least two weeks before your appointment.",
  "Do not take any blood-thinning medications, prenatal vitamins, Vitamin E, or nutritional shakes for at least 72 hours before your procedure, unless advised otherwise by your doctor. Tylenol is recommended for pain relief if needed.",
  "Refrain from vigorous exercise on the day of your procedure.",
  "Do not expose your skin to sun tanning or tanning beds for 2 weeks before your appointment. If you arrive with a sunburn, your appointment may need to be rescheduled, and your deposit forfeited.",
  "Do not tweeze, thread, wax, tint, or perform electrolysis on your eyebrows for 3 days before the procedure.",
  "Ensure any scars from surgery or injury are fully healed for at least one year before your appointment.",
  "Avoid alcohol consumption 24 hours before your procedure.",
  "Refrain from consuming coffee or other caffeinated products 24 hours before your appointment.",
  "Please be aware that sensitivity may be heightened during your menstrual cycle.",
];
export const eyebrowslongTermCare = [
  "To maintain the shape and depth of color, regular touch-up appointments are necessary. Without maintenance, the color will naturally lighten over time. Factors influencing fading include skin type, lifestyle, sun exposure, pigment color, broken capillaries, and iron deficiency.",
  "For consistently attractive brows, we recommend scheduling a touch-up appointment annually.",
  "Apply high-quality sunscreen on your brows if you expect prolonged sun exposure, as sunlight can accelerate fading.",
  "Inform technicians about your cosmetic tattoo before undergoing chemical peels, laser procedures, or MRI scans to ensure proper care.",
  "Avoid using Retin-A/Tretinoin, hydroquinone, or other skin exfoliants near the brow area, as they can cause premature fading of your cosmetic tattoo.",
  "Chemical peels, acid peels, and lightening products targeting hyper-pigmentation on the forehead can also contribute to the premature fading of eyebrows.",
  "Exfoliating cleansers, scrubs, and acne-fighting products with acids should be avoided around the tattooed area to prevent rapid pigment exfoliation and premature fading.",
];
export const eyelinerTattooPreCare = [
  "Refrain from wearing mascara.",
  "Ensure your eyes are free of makeup and moisturizer.",
  "Bring sunglasses for sensitivity to light post-procedure.",
  "Remove contact lenses before the procedure.",
  "Consider arranging for a ride home if light sensitivity is a concern, and have sunglasses ready as your eyes may be dilated afterward.",
  "Avoid lash tinting for 2 weeks prior and refrain from dyeing or perming lashes for 2 days before.",
  "Obtain medical clearance from your physician if you've had Lasik or Cataract Surgery.",
  "Remove eyelash extensions at least 1 week before your appointment.",
  "Discontinue use of eyelash growth serums 4 weeks prior to the procedure, including Latisse and similar products for 2 weeks beforehand.",
  "Avoid botox injections in the eye area for 2 weeks before the procedure.",
  "Ensure your skin is free of irritations such as blemishes, eczema, and psoriasis.",
  "Cease Accutane medication for at least one year prior to your eyeliner tattoo.",
  "Stop using blood-thinning supplements like Fish Oil, Vitamin C, Glucosamine, Evening Primrose Oil, Ginger, and Ginkgo one week before your appointment to prevent patchy healing.",
  "Discontinue anti-aging, skin brightening, or anti-acne skincare products containing Alpha Hydroxy (AHA), Vitamin A, or Retinol for 30 days prior to your appointment to avoid patchy healing.",
  "Refrain from facials, microdermabrasion, microneedling, peels, or laser treatments for 4 weeks prior to your appointment.",
  "Avoid blood-thinning medications such as Ibuprofen, Aspirin, Advil, Niacin, Voltaren, Indocid, Prenatal Vitamins, Vitamin E, or Nutritional Shakes for at least 72 hours before your procedure unless medically necessary.",
  "Refrain from intense workouts on the day of your procedure.",
  "Avoid sun tanning or tanning beds for 2 weeks prior to your appointment to prevent pigment issues due to exfoliation from sunburn.",
  "Ensure scars from surgery or injury have healed for at least one year prior to your appointment.",
  "Abstain from alcohol consumption 24 hours before your procedure.",
  "Avoid consuming coffee or other caffeine products 24 hours prior to your appointment.",
  "Note that sensitivity may increase during your menstrual cycle.",
];
export const eyelinerlongTermCare = [
  "Refrain from using eye drops or similar preparations regularly, as they can accelerate pigment fading in permanent eyeliner.",
  "Sun exposure and tanning beds can lead to pigment fading and discoloration.",
  "Avoid using chemical exfoliants or heavy creams around the eyes, as these products may cause premature fading.",
  "Do not undergo laser treatments near the eye area (including IPL), as certain lasers can alter pigment color, causing it to darken or lighten.",
  "You may still need additional eye makeup application after the tattoo procedure to achieve your desired aesthetic.",
  "Inform technicians performing laser procedures or MRI scans about your cosmetic tattoo.",
  "Discontinue use of Retin-A/Tretinoin, hydroquinone, or any rapid skin exfoliation products near the eye area, as they can cause premature fading of your cosmetic tattoo.",
  "Chemical peels, acid peels, and lightening or brightening products targeting hyper-pigmentation near the eye area can lead to permanent eyebrow fading.",
  "Exfoliating cleansers, scrubs, and acne-fighting products containing acids can accelerate skin exfoliation, potentially causing premature fading of the pigment.",
];

export const cleaningEyelinerInstructions = [
  "Clean your eyes every morning and evening using water wipes provided, gently patting the eye area. Apply the provided ointment with a clean Q-tip.",
  "Ensure the area is dry before applying ointment",
  "Apply ointment sparingly 2 to 3 times daily using a gentle dabbing motion with the Q-tip to avoid disrupting pigment bonding. This keeps the area moist and aids in healing acceleration. If scabs form, keep them covered with ointment and avoid rubbing the area.",
  "On Days 1 to 2, your eyes may appear swollen and slightly crusty upon waking. Swelling should diminish with upright positioning, blinking, and increased circulation to the area. Avoid heavy lifting, exertion, and crying.",
  "By Day 2, swelling should reduce, but the area may feel tight and sore if touched.",
  "From Day 3 to 4, the top layer of skin may begin to flake in small, stitch-like lines, although not everyone notices this exfoliation. **Do not pick at it**, as this may pull out pigment and result in uneven color. Blinking helps remove detached pigment pieces.",
  "By Day 7 to 9, all shedding should be complete, allowing you to safely shower facing the shower head. During this phase, pigment may seem to have faded, which is normal during the healing process. The color will eventually re-emerge.",
  "On Day 10, any faded pigment should have returned. The current appearance closely reflects the healed result. If adjustments are needed, wait the final 4 weeks before returning for a touch-up procedure.",
];

export const aftercareEyelinerInstructions = [
  "Avoid direct water exposure on the eyeliner and refrain from using cleaners, soaps, and heavy creams. Use makeup wipes instead.",
  "Do not use contact lenses for 48 hours, mascara until outer healing is complete, or old mascara tubes. Replace with new ones.",
  "Avoid applying makeup for 10 days and using an eyelash curler on the day of the procedure.",
  "Abstain from saline eye drops/wash or mascara until fully healed.",
  "Refrain from rubbing, picking, or scratching to prevent uneven healing and potential infection leading to scarring. Let scabs and dry skin naturally exfoliate.",
  "Avoid direct sun exposure/tanning or tanning beds for 4 weeks after the procedure. Wear sunglasses and a hat outdoors for protection.",
  "During the healing phase, eyes are sensitive to pet dander, gardening, dust, and dirty hands. Sunglasses provide additional protection from sunlight.",
  "Steer clear of heavy sweating, exercise, sports, sauna, swimming, steam rooms, jacuzzis, and long hot showers for 10 days. While eyes heal well, they remain susceptible to secondary infections like conjunctivitis—seek medical attention if needed.",
  "Refrain from using skincare or cosmetics on the treated area and avoid exfoliants, acids such as glycolic acid, alpha hydroxy acids, lactic acid, salicylic acid, and Vitamin A around the tattooed area for 30 days to prevent premature fading.",
  "Use a fresh pillowcase and avoid sleeping on your face for the first 10 days.",
  "Postpone facials, microdermabrasion, microneedling, peels, or laser treatments for at least 4 weeks, as they may affect pigment appearance.",
  "Limit alcohol intake to facilitate timely wound healing.",
  "Avoid driving in open-air vehicles like convertibles, boats, bicycles, or motorcycles.",
  "Postpone heavy household cleaning tasks involving garage or storage areas with airborne debris—maintain eye cleanliness and bacteria-free surroundings.",
];

export const pleaseNoteEyeliner = [
  "Over-the-counter Tylenol can provide temporary pain relief if needed.",
  "After healing, your eyeliner may appear lighter due to natural skin regeneration. Some areas may seem uneven or patchy despite proper care, which is normal as natural skin regeneration is beyond the artist's control. A touch-up session after 6-8 weeks ensures even brows and desired effects.",
  "Healed results vary per individual. The healing process outcomes are beyond our control. Additional touch-up sessions may be required post-second session (touch-up) to achieve optimal results, with additional charges potentially applicable.",
  "Adhering to proper post-care practices is crucial for achieving optimal results. When scheduling a cosmetic tattoo appointment, consider the procedure's healing time, influenced by your body's regeneration, age, immune system, and lifestyle.",
];

export const lipsTattooPreCare = [
  "It is imperative to consult your physician for a prescription of antiviral medication such as Valtrex or Zovirax before and after the procedure, particularly if you have a history of chicken pox, fever blisters, or cold sores. This precaution must be followed without exception. Begin taking the medication two days before your appointment and continue for two days post-procedure.",
  "Ensure that your lips are free from any irritations, including blemishes, eczema, or psoriasis.",
  "If you have been on Accutane, you must wait at least one year before undergoing a Cosmetic Tattoo procedure.",
  "Avoid taking Fish Oil, Vitamin C, Glucosamine, Evening Primrose Oil, or Ginger one week before your appointment, as these can thin the blood and affect pigment implantation. Continued use of these supplements may result in patchy healing.",
  "Discontinue the use of any anti-aging, skin brightening, or anti-acne skincare products containing Alpha Hydroxy (AHA), Vitamin A, or Retinol for at least 30 days before your appointment. These products can spread under the skin, leading to uneven healing of your cosmetic tattoo. You may resume your normal skincare routine approximately six weeks after your final session.",
  "Do not undergo any facials, microdermabrasion, microneedling, peels, or laser treatments for at least four weeks before your appointment.",
  "If you have had a lip implant, you must wait six months before scheduling a lip tattoo procedure.",
  "Avoid any blood-thinning medications or supplements for at least 72 hours before your procedure, unless medically unsafe to do so. Consult your doctor before stopping any medications such as Ibuprofen, Aspirin, Advil, Niacin, Voltaren, Indocid, Prenatal Vitamins, Vitamin E, or Nutritional Shakes. If you have a low pain tolerance, Tylenol is recommended.",
  "Refrain from strenuous exercise on the day of your procedure.",
  "Do not expose your lips to sun tanning or tanning beds for at least two weeks before your procedure. If you arrive with sunburned skin, your appointment will need to be rescheduled, and your deposit will be forfeited. Sun-damaged skin affects pigment retention and healing.",
  "Ensure that any scars from surgery or injury on your lips have fully healed for at least one year before your service.",
  "Refrain from consuming alcohol 24 hours before your procedure.",
  "Avoid consuming coffee or other caffeine products 24 hours before your appointment.",
  "Note that sensitivity may be heightened during your menstrual cycle.",
  "Get plenty of rest and stay hydrated by drinking lots of water in the days leading up to your procedure.",
];

export const actionAvoidedLips = [
  "Do not rub, pick, or scratch, as this can cause uneven healing and increase infection risk, potentially leading to scarring.",
  "Avoid stretching lips excessively through smiling or puckering, as well as smoking, as these movements can strain healing lip edges, which are sensitive mucous membranes rather than resilient skin.",
  "Refrain from direct sun exposure/tanning or using tanning beds for 4 weeks post-procedure. Wear a hat and sunglasses outdoors.",
  "Avoid smoking until lips are fully healed (approximately one week).",
  "Refrain from kissing, rubbing, or applying friction to the treated area until healing is complete to prevent color loss.",
  "Avoid heavy sweating, exercise, sports, swimming, saunas, steam rooms, jacuzzis, and long hot showers for 10 days.",
  "When showering, avoid direct water pressure on the treated area for 10 days; aquaphor can be applied to cover lips.",
  "Avoid using skincare or cosmetics on the treated area, including foundation or powders. Do not use products containing Retin-A, glycolic acids, anti-acne ingredients, AHA’s/BHA’s on the treated area.",
  "Avoid hot, spicy, salty, or acidic foods for the first 2 weeks; drink liquids through a straw for 3 days.",
  "Avoid toothpaste with whitening properties for 10 days.",
  "Avoid bleaching, waxing, electrolysis, or laser hair removal treatments near the treated area for 2 weeks.",
  "Do not use exfoliants, glycolic acid, alpha hydroxy acids, lactic acid, salicylic acid, fruit acids, or Vitamin A on the tattooed area for 30 days, as these can cause premature fading.",
  "Use a fresh pillowcase and avoid sleeping face-down for the first 10 days.",
  "Avoid scheduling facials, microdermabrasion, microneedling, peels, or laser treatments for at least 4 weeks, as these can alter pigment appearance.",
  "Limit alcohol intake to avoid delaying wound healing.",
  "Do not use Blistex, Carmex, or Chapstick while healing.",
  "Avoid heavy household cleaning that generates airborne debris.",
  "Avoid cosmetic injections on the lips for at least three weeks before your appointment.",
];

export const noteLips = [
  "All procedures must HEAL, PEEL, and FADE! Healing typically takes about 10 days, though this may vary based on your immune system, stress levels, and diet.",
  "Clients with sun damage, scar tissue (from injuries or cold sores), or implants may not achieve optimal results.",
  "Hyperpigmentation can occur unexpectedly in any lip client.",
  "True results become visible only after skin sloughs off and regenerates, a process that takes approximately 28 days.",
  "Variations in appearance are normal due to natural skin regeneration, which cannot be controlled by the artist.",
  "A touch-up session 6-8 weeks after the initial procedure helps refine color richness, symmetry, density, and desired effects.",
  "Healed results vary among individuals. The healing process for lips typically requires 1 to 3 sessions to complete.",
  "You may resume wearing lipstick once lips are fully healed. Use a new lipstick after any lip treatments.",
];

export const liplongTermCare = [
  "To preserve their shape and color depth, periodic touch-up appointments are necessary. Without maintenance, the color will naturally lighten over time. The rate of fading depends significantly on factors such as your skin type, lifestyle, sun exposure, choice of pigment color, presence of broken capillaries, and iron levels. For consistently vibrant lips, we recommend scheduling a touch-up once annually for optimal results.",
  "For prolonged sun exposure, apply a quality sunscreen specifically designed for lips to prevent fading. Smokers and individuals who frequently expose their skin to sunlight may experience faster color deterioration. Daily application of a lip balm with SPF is essential to maintain color integrity.",
  "If you plan to undergo chemical peels, laser procedures, or an MRI scan, inform the technician about your cosmetic tattoo. Due to the iron-oxide content in the pigments, these treatments require careful application to avoid affecting the tattooed area. Laser treatments, in particular, can cause pigments to darken if applied over the tattooed area.",
  "Avoid using Retin-A/Tretinoin, hydroquinone, or any rapid skin exfoliation products near the treated area, as they can accelerate the fading of your cosmetic tattoo.",
  "Similarly, refrain from using chemical peels, acid-based products, or brightening agents that target hyperpigmentation on or around the tattooed area, as they can prematurely fade the tattoo.",
  "Exfoliating cleansers, scrubs, or products containing acne-fighting ingredients should also be avoided, as they can lead to rapid exfoliation of the skin and increase the likelihood of premature fading of your cosmetic tattoo.",
];
